import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRequestData, usePrefs, useLabels } from 'wsm-common-data';
import logger from 'ws-scripts/modules/logger';
import { ErrorBoundary } from 'wsm-error-boundary';
import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import { isBrowser } from 'ws-scripts/modules/environment';
import { insertWIAPIImagesByInventory } from '../utilities/insertWIAPIImages';
import VehicleCards from '../components/VehicleCards';
import ComparePortal from '../components/ComparePortal';
import ErrorAlert from '../components/ErrorAlert';
import MediaModalContainer from '../components/MediaModalContainer';
import LowResults from '../components/noResults/LowResults';
import NewCarCustomizer from '../components/noResults/NewCarCustomizer';
import NoResults from '../components/NoResults';
import InfoAlert from '../components/InfoAlert';
import { waitForInventory } from '../utilities/waitForInvData';
import './style.scss';
import { getIsSmallScreen } from '../utilities/layout';
import { setLayout } from '../features/layout';
import { setUserProfile } from '../features/userProfile';

const Widget = () => {
	const { deviceType, widgetName, windowId } = useRequestData();
	const {
		imageDelayLoad,
		showVideoModalButton,
		lowResultsCount,
		showNccLinkNoLowResults
	} = usePrefs();
	const labels = useLabels();
	const { pageAlias } = useSelector((state) => state.widgetInfo);
	const layout = useSelector((state) => state.layout);
	const dispatch = useDispatch();

	const delayTimer = useRef(0);

	const initialInv = [...Array(6)].map((_, i) => ({
		uuid: `${i}`,
		isPlaceholder: true
	}));

	const [inventory, setInventory] = useState({
		accounts: {},
		incentives: {},
		pageInfo: {
			enableMediaCarousel: false
		},
		inventory: initialInv
	});
	const [appliedFilters, setAppliedFilters] = useState([]);
	const [alertMessage, setAlertMessage] = useState({});
	// if the imageDelayLoad pref is set, set the loading delay to true
	const [loadingDelayed, setLoadingDelayed] = useState(+imageDelayLoad > 0);
	const [isTransition, setIsTransition] = useState(false);
	const [requestFailed, setRequestFailed] = useState(false);

	// isLoading is true on initial page load
	// isRequesting is true when facet/filter interaction triggered
	const [isLoading, setIsLoading] = useState(true);
	const [isRequesting, setIsRequesting] = useState(false);
	const [isInitialRequest, setIsInitialRequest] = useState(true);
	// State variables stored in refs for accessing the current value inside callback functions
	const isLoadingRef = useRef();
	const isInitialRequestRef = useRef();
	isLoadingRef.current = isLoading;
	isInitialRequestRef.current = isInitialRequest;

	function removeWidgetHeight() {
		const widgetAppId = `${windowId}-app-root`;
		const widget = document.getElementById(widgetAppId);

		if (widget) {
			widget.classList.remove('min-vh-100');
		}
	}

	// handler for inserting new images on wiapi event
	function handleImagesInsertEventlistener(data) {
		if (isBrowser && window.DDC?.InvData?.srpReady) {
			// get the inventory
			const { inventory: globalInventory } =
				window.DDC.InvData.getInventory();
			if (globalInventory) {
				// build the updated inventory with inserted 3rd party content
				const updatedInventory = insertWIAPIImagesByInventory(
					globalInventory,
					data.detail.content
				);
				// if imageDelayLoad preference is set, clear the delayTimer
				if (+imageDelayLoad > 0) {
					clearTimeout(delayTimer.current);
				}

				// update state with the new inventory and toggle the loading delay off
				setInventory((prevInventory) => ({
					...prevInventory,
					inventory: updatedInventory
				}));
				setLoadingDelayed(false);
			}
		}
	}

	function initializeListings() {
		const invData = window.DDC.InvData;
		// get the global inventory meta data
		const globalData = invData.inventory;
		// get the new gallery content to be inserted
		const galleryContent = window?.DDC?.PrivateAPI?.getAllGalleryContent
			? window.DDC.PrivateAPI.getAllGalleryContent('vehicle-media')
			: {};

		// build the updated inventory with inserted 3rd party content
		let updatedInventory;
		if (window.DDC.InvData.srpReady) {
			updatedInventory = insertWIAPIImagesByInventory(
				globalData.inventory,
				galleryContent
			);
		}
		// get alert message
		const alertMessageFromData = Object.prototype.hasOwnProperty.call(
			invData || {},
			'alertMessage'
		)
			? invData?.alertMessage
			: {};

		// build initialized state
		setIsRequesting(false);
		setIsLoading(false);
		setInventory({
			...globalData,
			...(updatedInventory && { inventory: updatedInventory })
		});
		setAlertMessage(alertMessageFromData);

		removeWidgetHeight();
	}

	useEffect(() => {
		if (isBrowser) {
			waitForInventory(widgetName);
			const { innerWidth: viewportWidth } = window;
			dispatch(
				setLayout({ isSmallScreen: getIsSmallScreen(viewportWidth) })
			);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// If imageDelayLoad preference is set, start the delay timer
		// This causes a rerender
		if (+imageDelayLoad > 0) {
			delayTimer.current = setTimeout(() => {
				// toggle delay loading off when the timer expires
				setLoadingDelayed(false);
			}, +imageDelayLoad);
		}

		return () => {
			clearTimeout(delayTimer.current);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// This causes a rerender
		// Listen for event to update with new images
		if (isBrowser) {
			window.addEventListener(
				'wiapiAllVehiclesMediaUpdated',
				handleImagesInsertEventlistener
			);
		}

		// Send info to NR about default layout
		setNewRelicCustomAttribute('srpLayout', layout.layout);

		if (isBrowser && window.DDC?.dataLayer?.page?.attributes) {
			window.DDC.dataLayer.page.attributes.srpLayout = layout.layout;
		}

		if (isBrowser && window.DDC?.InvData?.inventory?.inventory) {
			initializeListings();
		}

		return () => {
			window.removeEventListener(
				'wiapiAllVehiclesMediaUpdated',
				handleImagesInsertEventlistener
			);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const pubsub = (isBrowser && window.DDC?.pubsub) || undefined;

		if (pubsub) {
			// Subscribing to fetch request events in ws-inv-data
			pubsub.subscribe('ws-inv-data/inventory-request', () => {
				if (!isLoadingRef.current && !isInitialRequestRef.current) {
					setIsRequesting(true);
				}
				if (isInitialRequestRef.current) {
					setIsInitialRequest(false);
				}
			});

			// These (both of these initializeListings calls in total) cause 1 rerender
			window.DDC.pubsub.subscribe('ws-inv-data/inventory', () => {
				initializeListings();
			});
			// There is a chance that the topic 'ws-inv-data/inventory' is published by inv-data
			// before the inv-listing subscribes to it. This results in the listings being stuck
			// in the skeleton loader phase. This issue does not happen when faceting.

			// We fire the initializeListings function under these circumstances:
			// When the component mounts (ie: does not trigger on facet interactions),
			// AND when the state variable 'isLoading' is still true
			// AND when the DDC. InvData.inventory object is not empty (data has already been fetched)
			if (
				isBrowser &&
				isLoading &&
				window.DDC?.InvData?.inventory?.inventory
			) {
				initializeListings();
			}

			// This causes a rerender
			pubsub.subscribe('ws-inv-data/facets', () => {
				setAppliedFilters(window.DDC.InvData.getAppliedFilters());
			});
			pubsub.subscribe('ws-inv-data/request-failed', () => {
				setRequestFailed(true);
				setIsLoading(false);
				removeWidgetHeight();
			});
			pubsub.subscribe('instant-ePrice-completed', () => {
				let profile = window.DDC.userProfile;
				if (
					!(
						typeof profile === 'object' &&
						Object.keys(profile).length > 0
					)
				) {
					profile = null;
				}
				dispatch(setUserProfile({ userProfile: profile }));
			});
			// Transition display for mobile on ws-inv-filters events
			// This causes rerender
			if (deviceType === 'MOBILE') {
				pubsub.subscribe('ws-inv-filters/modal-show', () => {
					setIsTransition(true);
				});
				pubsub.subscribe('ws-inv-filters/modal-hide', () => {
					setIsTransition(false);
				});
			}
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let loadListener;
		if (isBrowser && !isLoading && !requestFailed) {
			// scroll to anchor link if it exists
			const { hash } = window.location;
			if (hash) {
				if (document.readyState === 'complete') {
					window.DDC.scrollToAnchor($(hash));
				} else {
					loadListener = () => {
						window.DDC.scrollToAnchor($(hash));
					};
					window.addEventListener('load', loadListener);
				}
			}
		}

		return () => {
			window.removeEventListener('load', loadListener);
		};
	}, [isLoading, requestFailed]);

	useEffect(() => {
		if (isBrowser && !isLoading) {
			const pubsub = window.DDC?.pubsub || undefined;

			if (pubsub) {
				pubsub.publish('ws-inv-listing-update');
			}

			// Set a flag so the Web Integration API knows the initial inventory view has rendered.
			window.DDC.PrivateAPI = window.DDC.PrivateAPI || {};
			window.DDC.PrivateAPI.wsInvListingRendered = true;

			// Required refresh to allow `.dialog` elements to initialize jQuery
			// dialog and slide-in functionality on wsm-vehicle-cta-display buttons
			if (deviceType === 'MOBILE') {
				window.DDC.mobile.slidein.init();
			} else if (typeof $ === 'function') {
				$(document).trigger('modulesRefresh', {
					modules: {
						dialog: window.DDC.modules.dialog
					}
				});
			}
		}
	}, [isLoading, inventory, deviceType]);

	const { pageInfo } = inventory;
	const { enableMediaCarousel } = pageInfo;
	const isNoResults = inventory?.pageInfo?.totalCount === 0;
	const isAlertMessageEmpty =
		alertMessage === null ||
		alertMessage === undefined ||
		(Object.keys(alertMessage).length === 0 &&
			alertMessage.constructor === Object);
	const isMyCarsPage = pageAlias === 'INVENTORY_LISTING_SHOPPING_ACTIVITY';
	const isLowResult =
		inventory?.pageInfo?.totalCount <= +lowResultsCount &&
		+lowResultsCount > 0 &&
		!isMyCarsPage;

	if (requestFailed) {
		addNewRelicPageAction('WS INV LISTING REQUEST FAILED', {
			requestFailed: true
		});
	}

	return (
		<ErrorBoundary
			errorHandler={(error, errorInfo) => {
				setNewRelicCustomAttribute(
					'SRP ERROR',
					`ws-inv-listing error boundary.\n${error}`
				);
				const newError = new Error(
					`ws-inv-listing error boundary.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			{requestFailed ? (
				<ErrorAlert
					errorLabel={labels.get('TECHNICAL_DIFFICULTIES_VERBIAGE')}
				/>
			) : (
				<div
					role="region"
					aria-live="polite"
					aria-busy={isLoading}
					aria-label={labels.get('MATCHING_VEHICLES')}
					// TODO add data-async-widget="true" to widget container renderer in ws-scripts
					data-async-widget
				>
					<ComparePortal />
					{isNoResults ? (
						<>
							{!isAlertMessageEmpty && isMyCarsPage && (
								<InfoAlert {...{ alertMessage }} />
							)}
							{isAlertMessageEmpty && isMyCarsPage && (
								<NoResults {...{ appliedFilters }} />
							)}
							{!isMyCarsPage && (
								<>
									<LowResults
										isNoResults={isNoResults}
										appliedFilters={appliedFilters}
									/>
									{showNccLinkNoLowResults === 'true' && (
										<NewCarCustomizer />
									)}
								</>
							)}
						</>
					) : (
						<>
							<VehicleCards
								{...{
									inventoryObj: inventory,
									enableMediaCarousel,
									isLoading,
									isTransition,
									isRequesting,
									loadingDelayed,
									appliedFilters
								}}
							/>
							{isLowResult ? (
								<LowResults
									isNoResults={isNoResults}
									appliedFilters={appliedFilters}
								/>
							) : null}
							{isLowResult &&
								showNccLinkNoLowResults === 'true' && (
									<NewCarCustomizer />
								)}
							{!isAlertMessageEmpty && isMyCarsPage && (
								<InfoAlert {...{ alertMessage }} />
							)}
						</>
					)}
					{showVideoModalButton === 'true' && <MediaModalContainer />}
				</div>
			)}
		</ErrorBoundary>
	);
};

export default Widget;
