import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFlags, useLabels, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import VehicleLink from './VehicleLink';
import { getFlagValue } from '../../../utilities/flags';
import { addImageParams } from '../../../utilities/images';

const VehicleImage = ({
	image,
	imageIndex,
	placeholderHeight,
	link,
	uuid,
	enableImage,
	onImageLoad,
	isLoaded
}) => {
	const [isInitialLoad, setIsInitialLoad] = useState(isLoaded);
	const [placeholderStyles, setPlaceholderStyles] = useState(null);
	const { titleAttributeBefore, titleAttributeAfter } = usePrefs();
	const flags = useFlags();
	const labels = useLabels();
	const imageRef = useRef();
	const { uri = 'https://static.dealer.com/images/blank.gif', title = '' } =
		image || {};
	const imageFetchPriority = getFlagValue(
		flags,
		'srp-image-fetch-priority',
		'_toggleSRPImageFetchPriority'
	);
	let imageLoadingPriority = getFlagValue(
		flags,
		'srp-image-loading-priority',
		'_toggleSRPImageLoadingPriority'
	);
	const isFixHeightImage = flags['srp-image-fix-height'];

	setNewRelicCustomAttribute('srpImageFetchPriority', imageFetchPriority);
	setNewRelicCustomAttribute('srpImageLoadingPriority', imageLoadingPriority); // We want to track the LD value, not the final value.

	// Existing behavior as a fallback in case something goes awry.
	if (imageLoadingPriority === 'original') {
		const canNativeLazyLoad = 'loading' in HTMLImageElement.prototype;
		const shouldNativeLazyLoad = canNativeLazyLoad && imageIndex === 0; // only need to lazy load the first image in the carousel
		imageLoadingPriority = shouldNativeLazyLoad ? 'lazy' : null;
	}
	const onLoad = () => {
		if (imageRef.current.src) {
			setIsInitialLoad(true);
			onImageLoad(imageRef);
		}
	};
	let src = uri;
	if (image) {
		// don't add params to blank.gif
		src = addImageParams(uri);
	}

	useEffect(() => {
		if (isInitialLoad) {
			setPlaceholderStyles(null);
		} else if (placeholderHeight) {
			setPlaceholderStyles({
				height: placeholderHeight,
				overflow: 'hidden',
				padding: 0
			});
		}
	}, [isInitialLoad, placeholderHeight]);

	if (!enableImage) {
		return null;
	}

	const imageTitle = setClassNames([
		labels.get(titleAttributeBefore),
		title,
		labels.get(titleAttributeAfter)
	]);

	return (
		<VehicleLink link={link} uuid={uuid}>
			<div
				className={setClassNames([
					'img-container',
					'img-placeholder',
					'img-loaded', // todo Do we want the opacity transition here?
					isFixHeightImage ? 'fix-height' : ''
				])}
				style={placeholderStyles}
			>
				<img
					loading={imageLoadingPriority}
					// eslint-disable-next-line react/no-unknown-property
					fetchpriority={
						imageFetchPriority !== 'auto'
							? imageFetchPriority
							: null
					}
					alt={imageTitle}
					ref={imageRef}
					title={imageTitle}
					onLoad={onLoad}
					onError={onLoad}
					className="w-100"
					src={src}
					style={{
						aspectRatio: uri.includes('blank')
							? '4 / 3'
							: 'auto 4 / 3'
					}}
				/>
			</div>
		</VehicleLink>
	);
};

VehicleImage.propTypes = {
	image: PropTypes.shape({
		uri: PropTypes.string,
		title: PropTypes.string
	}).isRequired,
	imageIndex: PropTypes.number.isRequired,
	link: PropTypes.string,
	uuid: PropTypes.string.isRequired,
	enableImage: PropTypes.bool,
	onImageLoad: PropTypes.func,
	placeholderHeight: PropTypes.number,
	isLoaded: PropTypes.bool
};

VehicleImage.defaultProps = {
	link: '',
	enableImage: true,
	onImageLoad: () => {},
	placeholderHeight: null
};

export default VehicleImage;
