import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import {
	selectIsTabbedLayout,
	selectIsGridLayout
} from '../../../utilities/layout';
import FooterSkeleton, {
	FooterSkeletonContainerClasses
} from '../skeleton/FooterSkeleton';
import {
	useCompareVehicles,
	useFooterLayoutClasses,
	useDeviceType
} from '../../../hooks';
import Footer from './Footer';
import CompareContent from './CompareCheckbox/CompareContent';

const FooterContainer = ({ shouldFullyRenderCard, ...props }) => {
	const {
		shouldShowCompare,
		vehicle: { uuid }
	} = props;
	const isDesktop = useDeviceType();
	const isTabbedLayout = useSelector((state) => selectIsTabbedLayout(state));
	const isGridLayout = useSelector((state) => selectIsGridLayout(state));
	const compareVehicles = useCompareVehicles(uuid);
	const { selected } = compareVehicles;

	const footerContainerClasses = useFooterLayoutClasses(
		isDesktop,
		isTabbedLayout,
		shouldFullyRenderCard,
		isGridLayout,
		shouldShowCompare
	);

	const dividerClasses = setClassNames(['mx-4', 'my-0']);

	if (!shouldFullyRenderCard) {
		footerContainerClasses.push(...FooterSkeletonContainerClasses);
	}

	return (
		<>
			<hr className={dividerClasses} />
			<div className={setClassNames(footerContainerClasses)}>
				{shouldFullyRenderCard ? (
					<Footer {...props} {...compareVehicles} />
				) : (
					<FooterSkeleton />
				)}
			</div>

			{Boolean(isTabbedLayout && selected) && (
				<div className="w-100 pb-4 ddc-font-size-small text-center">
					<CompareContent
						selected={selected}
						isInline={!isTabbedLayout}
					/>
				</div>
			)}
		</>
	);
};

FooterContainer.propTypes = {
	shouldFullyRenderCard: PropTypes.bool.isRequired,
	shouldShowCompare: PropTypes.bool,
	vehicle: PropTypes.shape({
		uuid: PropTypes.string,
		link: PropTypes.string
	}).isRequired
};

export default FooterContainer;
