/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	scmPromos: {
		personalized: false,
		promosList: []
	},
	ctaCardItemlist: []
};

export const contentCardsSlice = createSlice({
	name: 'contentCards',
	initialState,
	reducers: {
		setScmPromos(state, action) {
			state.scmPromos = action.payload;
		},
		setCtaCardItemlist(state, action) {
			state.ctaCardItemlist = action.payload;
		}
	}
});

export const { setScmPromos, setCtaCardItemlist } = contentCardsSlice.actions;

export default contentCardsSlice.reducer;
