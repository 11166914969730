import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';
import Delivery from './Delivery';
import { extractMiles } from '../../../utilities/extractMiles';

const LocationDistance = ({ label, accountId }) => {
	const dataHref = `/fragments/location-distance.htm?selectedAccountId=${accountId}`;
	const linkProps = {
		href: dataHref,
		className: 'text-muted dialog',
		'data-width': '800',
		'data-dialog-class': 'sms-form-dialog',
		'data-title': 'Vehicle Location',
		'data-href': dataHref
	};
	const prefs = usePrefs();
	const showDelivery = prefs.deliveryEnabled === 'true';

	// Label comes in formatted as '!accountName ::: !geodist' from cms-web
	// This splits it and remove the underline on the account name
	if (label.includes(':::')) {
		const [accountName, geoDist] = label.split(':::');
		const distanceValue = extractMiles(geoDist);
		return (
			<>
				{showDelivery && distanceValue !== null && (
					<Delivery distance={distanceValue} />
				)}
				<div className="text-content-container">
					<a
						{...linkProps}
						className={`${linkProps.className} text-decoration-none`}
					>
						{accountName}
					</a>
					<a {...linkProps}>{geoDist}</a>
				</div>
			</>
		);
	}

	return (
		<div className="text-content-container">
			<a {...linkProps}>{label}</a>
		</div>
	);
};

LocationDistance.propTypes = {
	label: PropTypes.string.isRequired,
	accountId: PropTypes.string.isRequired
};

export default LocationDistance;
